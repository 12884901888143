<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            ref="identity"
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :type-label="$t('forms_flujo.form1_persona.SP.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.SP.identity_doc')"
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-phone-number
            v-model="formulario1.telefono"
            :error="errors.get('telefono')"
            default-country-code="+34"
            required
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model.number="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model.number="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="pais_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.nacionalidad')"
          >
            <el-select
              ref="pais_nacimiento"
              v-model="formulario1.pais_nacimiento"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
            >
              <el-option
                v-for="item in paises"
                :key="item.id"
                :label="item.nacionalidad"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.nacionalidad')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              editable
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.SP.fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="mail"
            :label="$t('forms_flujo.form1_persona.SP.mail')"
            :error="errors.get('mail')"
          >
            <el-input
              ref="mail"
              v-model="formulario1.mail"
              :type="'text'"
              auto-complete="on"
              :placeholder="$t('forms_flujo.form1_persona.SP.mail')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="destino_prestamo"
            :label="$t('forms_flujo.form1_persona.SP.destino_prestamo')"
            :error="errors.get('destino_prestamo')"
          >
            <el-autocomplete
              ref="destino_prestamo"
              v-model="formulario1.destino_prestamo"
              :fetch-suggestions="getSuggest"
              :placeholder="$t('forms_flujo.form1_persona.SP.destino_prestamo')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form1_persona.SP.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              ref="ingreso_mensual"
              v-model.number="formulario1.ingreso_mensual"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.ingreso_mensual')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cuota_maxima"
            :label="$t('forms_flujo.form1_persona.SP.cuota_maxima')"
            :error="errors.get('cuota_maxima')"
          >
            <el-input
              ref="cuota_maxima"
              v-model.number="formulario1.cuota_maxima"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.cuota_maxima')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          v-for="(condition) in producto.conditions"
          :key="condition.id"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="row justify-content-between"
        >
          <pz-terms-and-conditions
            v-model="formulario1.conditions[condition.slug]"
            :prop="'condition_'+condition.slug"
            :name="condition.nombre"
          >
            <div v-html="condition.contenido" />
          </pz-terms-and-conditions>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :span="24"
          class="row justify-content-between"
        >
          <el-form-item
            prop="accept_advertising"
            :error="errors.get('accept_advertising')"
          >
            <el-checkbox
              ref="accept_advertising"
              v-model="formulario1.accept_advertising"
              :label=" $t('forms_flujo.form1_persona.SP.accept_advertising')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, type,EspacioBlanco} from "@/commons/utils/Rules";
import {getPaises} from "@/routes/api/resources";
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {isEmpty} from "@/commons/utils/functions";
import PzPhoneNumber from "@/commons/forms/Fields/PzPhoneNumber";

export default {
    name: 'PzPersonFormEs1',
    components: {PzPhoneNumber, PzIdentityDocument, PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {
        return {
            marital_status: [],
            paises: [],
            suggest: [],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: {},
                pais_nacimiento: '',
                fecha_nacimiento: '',
                mail: '',
                destino_prestamo: '',
                cuota_maxima: '',
                ingreso_mensual: '',
                identity: {},
            },
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [required(''),EspacioBlanco()],
                apellidos: [required(''),EspacioBlanco()],
                fecha_nacimiento: [required(''),],
                pais_nacimiento: [required('')],
                mail: [required(''), type({field: '', type: 'email'})],
                cuota_maxima: [required(''), type({field: '', type: 'number'}),],
                ingreso_mensual: [required(''), type({field: '', type: 'number'}),],
                destino_prestamo: [required(''),],
                estado_civil_id: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created() {
        this.fetchData();
        this.suggest = Object.values(this.$t('credit_motive')).map((value) => {
            return {value: value}
        });
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let paises = getPaises().then((res) => {
                this.paises = res.data;
            }).catch(() => {
                this.paises = [];
            });
            Promise.all([paises]).
                finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})
                });
        },
        getSuggest(queryString, cb) {
            let suggest = this.suggest;
            const self = this;
            let results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
                return (self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0);
            }) : suggest;
            cb(results);
        },


    },

}
</script>

