<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <pz-direction
        v-model="formulario1.direccion"
        ask-postal-code
      />

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.SP.civil_state')"
            :error="errors.get('estado_civil_id')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.estado_civil_id"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.civil_state')"
            >
              <el-option
                v-for="item in marital_status"
                :key="item.id"
                :label="item.estado"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.civil_state')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="genero"
            :label="$t('forms_flujo.form1_persona.SP.genero')"
            :error="errors.get('genero')"
          >
            <el-select
              ref="genero"
              v-model="formulario1.genero"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
            >
              <el-option
                v-for="item in genero"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.SP.genero')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="entidad_nacimiento"
            :label="$t('forms_flujo.form1_persona.SP.entidad_nacimiento')"
            :error="errors.get('entidad_nacimiento')"
          >
            <el-input
              ref="entidad_nacimiento"
              v-model.number="formulario1.entidad_nacimiento"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.entidad_nacimiento')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.carnet_conducir"
            :label="$t('forms_flujo.form1_persona.SP.tiene_carnet_conducir')"
            :error="errors.get('carnet_conducir')"
          >
            <el-select
              ref="info_extra.carnet_conducir"
              v-model="formulario1.info_extra.carnet_conducir"
            >
              <el-option
                :value="1"
                :label="$t('1')"
              />
              <el-option
                :value="0"
                :label="$t('0')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estado_civil_id"
            :label="$t('forms_flujo.form1_persona.SP.banco_deposito')"
            :error="errors.get('banco_deposito')"
          >
            <el-select
              ref="estado_civil_id"
              v-model="formulario1.banco_deposito"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.SP.banco_deposito')"
            >
              <el-option
                v-for="item in bancos"
                :key="item.id"
                :label="item.short_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="banco"
            :label="$t('forms_flujo.form1_persona.SP.cuenta_deposito')"
            :error="errors.get('cuenta_deposito')"
          >
            <el-input
              ref="banco"
              v-model.number="formulario1.cuenta_deposito"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.cuenta_deposito')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="20"
        type="flex"
        justify="center"
      >
        <pz-open-banking
          v-model="formulario1.instantor"
          prop="bank_data"
          :label="$t('forms_flujo.form1_persona.SP.comprobacion_sc')"
        />
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {getBancos, getEstadoCivil} from "@/routes/api/resources";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzDirection from "@/commons/forms/Fields/PzDirection";
import PzOpenBanking from "@/commons/forms/Fields/OpenBanking/PzOpenBanking";
import {mapGetters} from "vuex";
import {required} from "@/commons/utils/Rules";

export default {
    name: 'PzPersonFormEs2',
  components: {PzOpenBanking, PzDirection, PzFormFlujo},
    mixins: [baseFormFlujo],
    data() {
    var check_data = (rule, value, callback) => {
      if (!(this.formulario1.instantor.open_banking)) {
        return callback(new Error(this.$t('rules.should_validate_bank_data')));
      } else {
        return callback();
      }
    };
        return {
            reference: 'formulario1',
            genero: [
                {id: 'f', name: this.$t('forms_flujo.form1_persona.SP.femenino')},
                {id: 'm', name: this.$t('forms_flujo.form1_persona.SP.masculino')},
                {id: 'o', name: this.$t('forms_flujo.form1_persona.SP.otro')}
            ],
            studies: [],
            bancos: [],
            marital_status: [],
            vnz: false,
            formulario1: {
                direccion: {},
                estado_civil_id: '',
                genero: '',
                entidad_nacimiento: '',
                banco_deposito: '',
                cuenta_deposito: '',
                instantor: {},
                info_extra: {
                    carnet_conducir: '',
                },
                numero_documento: '',
            },
            rules_formulario1: {
                genero: [required('')],
                entidad_nacimiento: [required('')],
                bank_data:[{validator: check_data, trigger: 'change'}],
                'instantor.cuenta': [required('')],

            },

        };
    },
    computed: {
        ...mapGetters(['applicant',])
    },
    created() {
        this.fetchData();
    },
    mounted() {
        this.formulario1.numero_documento = this.applicant.numero_documento;
    },
    methods: {
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let estado = getEstadoCivil().then((res) => {
                this.marital_status = res.data;
            }).catch(() => {
                this.marital_status = [];
            });
            let bancos = getBancos().then((res) => {
                this.bancos = res.data;
            }).catch(() => {
                this.bancos = [];
            });
            return Promise.all([estado, bancos]).then(() => {
            }).finally(() => {
                this.$store.dispatch('loader/down', {trigger: this.$options.name})
            });
        },
    },
}
</script>

